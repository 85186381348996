<template>
<!-- *************************** 加速页面 ****************************** -->
  <div>
    <JiasuTop/>
    <AcclerateWays/>
  </div>
</template>
<script>
import JiasuTop from "@/views/JiasuTop.vue";
import AcclerateWays from "@/views/AcclerateWays.vue";
export default {
  components:{
    JiasuTop,
    AcclerateWays
  }
}
</script>
<style lang="scss">

</style>